import React, { ReactElement } from 'react';
import { Container } from 'react-bootstrap';
import { Layout } from '../components/common';
import ContentImageRow from '../components/common/ContentImageRow';
import BannerSection from '../components/common/contentSections/BannerSection';
import ContentHero from '../components/common/contentSections/ContentHero';
import HeadingSection from '../components/common/contentSections/HeadingSection';
import TypesSection from '../components/common/contentSections/TypesSection';
import ImageContentRow from '../components/common/ImageContentRow';
import { ContentData } from '../utils/entities';

const AccessGatewayContentData: ContentData[] = [
    {
        id: 1,
        title: `Apps`,
        desc: `Connect any application of your choice, on any device easily`,
        imageUrl: `images/apps@3x.png`,
    },
    {
        id: 2,
        title: `Customize Profile`,
        desc: `User-friendly admin console enables admin to modify profiles, assign authorization`,
        imageUrl: `images/customize-profile.svg`,
    },
    {
        id: 3,
        title: `Users`,
        desc: `Add or remove any user, track customer behaviour on the platform and access detailed information about users including the time of login, location, and device specification from anywhere at any time`,
        imageUrl: `images/users-access-gateway.svg`,
    },
];

const AccessGateway = (): ReactElement => (
    <Layout>
        <Container>
            <ContentHero
                heading="AccessGateway"
                desc=" StackIAM allows you to integrate with other businesses and give your business partners access to information they might need."
                buttonText="Try StackIAM"
                imageUrl="/images/access-gateway.svg"
            />
            <TypesSection
                heading="Access Gateway Types"
                subheadingSection1="Company and contract staff Access"
                subheadingSection2="Individual Access"
                descSection1="Easily connect with partners that have their own IDP or access management deployment; federate using SAML or OIDC."
                descSection2="Connect your customer's LDAP or Active Directory; synchronize user accounts to Universal Directory."
                imageUrlSection1="images/office-staff-access@3x.png"
                imageUrlSection2="images/individual-user@3x.png"
            />
            <HeadingSection
                heading="Features"
                subheading="Access Gateway"
                desc="Whether you're looking for a two-factor authentication solution to meet compliance targets, or building a full MFA security framework, Okta is the adaptive multi-factor authentication vendor that has you covered.
                Implement a variety of different factors for authentication across usability and assurance levels:"
            />
            {AccessGatewayContentData.map((item) => {
                if (item.id % 2 === 0) {
                    return <ContentImageRow key={item.id} {...item} />;
                } else {
                    return <ImageContentRow key={item.id} {...item} />;
                }
            })}
            <BannerSection />
        </Container>
    </Layout>
);

export default AccessGateway;
